/* styles.css */
.forecast {
  display: flex;
  flex-direction: row;
}

.forecast-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}


.forecast-time{
font-size: 12px;
 
}